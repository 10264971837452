import React from 'react'
import styled from 'styled-components'
import { media } from '@caldera-digital/theme'

const PageWrapperContainer = styled.div`
  min-height: calc(100vh - ${props => props.theme.desktopHeaderHeight});

  ${media.forSmallOnly`
    min-height: calc(100vh - ${props => props.theme.mobileHeaderHeight});
    margin-top: ${props => props.theme.mobileHeaderHeight};
  `}
`

export const PageWrapper = React.forwardRef(({ children }, ref) => {
  return <PageWrapperContainer ref={ref}>{children}</PageWrapperContainer>
})
