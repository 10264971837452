export const theme = {
  maxContainerWidth: '1080px',
  darkGray: '#4d4d4d',
  defaultFont: '"Karla", sans-serif',
  defaultHeaderFont: '"Work Sans", sans-serif',
  defaultFontColor: '#003E51',
  defaultFontSize: '18px',
  defaultHeaderColor: '#0085AD',
  defaultInputBackgroundColor: '#f5f5f5',
  defaultMobileFontSize: '16px',
  defaultTransition: 'all 0.2s linear',
  desktopHeaderHeight: '95px',
  disabledColor: '#888888',
  errorColor: '#e44100',
  focusColor: '#003E51',
  grayText: '#707070',
  headerFont: '"Work Sans", sans-serif',
  lightGray: '#B3B3B3',
  linkColor: '#0085AD',
  mobileHeaderHeight: '70px',
  primaryColor: '#003E51',
  secondaryColor: '#0085AD',
  hoveredSecondaryColor: '#007191',
  pressedSecondaryColor: '#005972',
  lightBackgroundColor: '#f2f9fb',
  borderBlurColor: '#CFE7EE',
  white: '#ffffff',
}
