import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Swoosh } from '../assets/cam-swoosh-whitespace.svg'
import { ReactComponent as MobileSwoosh } from '../assets/cam-swoosh-whitespace-mobile.svg'
import { media } from '@caldera-digital/theme'
import { useMeasure } from '../hooks'
import { isIE } from '../utils/utilityService'

const HeroComponentContainer = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .swoosh {
    width: 100%;
    min-width: 1000px;
    fill: ${props => props.theme.primaryColor};
    position: absolute;
    top: 0;
    left: 0;
  }

  .mobileSwoosh {
    display: none;
  }

  > canvas {
    width: 100%;
    min-width: 1000px;
    visibility: hidden;
    display: block;
  }

  ${media.forSmallOnly`
    background: url(${props => props.backgroundImage}) no-repeat top center;
    background-size: cover;
    height: calc(100vh - ${props => props.theme.mobileHeaderHeight});

    .swoosh {
      display: none;
    }

    canvas {
      display: none;
    }

    .mobileSwoosh {
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  `}
`

const HeroContent = styled.div`
  position: absolute;
  max-width: 515px;
  bottom: 4rem;
  left: 4rem;

  @media (max-width: 1200px) {
    bottom: 3rem;
  }

  ${media.forSmallOnly`
    left: 50%;
    bottom: 1.75rem;
    width: 100%;
    padding: 0 1rem;
    transform: translateX(-50%);
  `}

  @media (max-width: 370px) {
    bottom: 1rem;
  }
`

export const HeroHeaderText = styled.h1`
  color: ${props => props.theme.primaryColor};
  text-transform: uppercase;
  font-size: 3.5rem;
  margin-bottom: 1rem;

  @media (max-width: 1200px) {
    font-size: 2.5rem;
  }
`

export const HeroText = styled.p`
  color: ${props => props.theme.grayText};
  font-size: 1.12rem;

  @media (max-width: 1200px) {
    font-size: 1rem;
  }
`

const ImageBackground = styled.div`
  background: url(${props => props.backgroundImage}) no-repeat top center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  /* IE11 hack because the canvas doesn't size correctly */
  bottom: ${isIE ? '110px' : '50px'};
  width: 100%;

  @media (max-width: 1100px) {
    bottom: ${isIE ? '180px' : '50px'};
  }

  ${media.forSmallOnly`
    display: none;
  `}
`

export const Hero = ({ backgroundImage, renderHeroText = () => null }) => {
  const [bind, bounds] = useMeasure()

  return (
    <HeroComponentContainer backgroundImage={backgroundImage} {...bind}>
      {/* For IE11 because it doesn't scale the canvas perfectly */}
      <ImageBackground backgroundImage={backgroundImage} />
      <Swoosh className="swoosh" />
      {/* IE11 doesn't scale SVGs, but it does scale canvas elements! */}
      {bounds.width > 1800 ? (
        <canvas width="1931" height="964" />
      ) : (
        <canvas width="1801" height="834" />
      )}

      <MobileSwoosh className="mobileSwoosh" />
      <HeroContent>{renderHeroText(bounds)}</HeroContent>
    </HeroComponentContainer>
  )
}

Hero.Header = HeroHeaderText
Hero.Text = HeroText
