import 'react-image-gallery/styles/css/image-gallery.css'

import { media } from '@caldera-digital/theme'
import React from 'react'
import ImageGallery from 'react-image-gallery'
import styled from 'styled-components'

import { PageWrapper, ProductCard, Section } from '../components'
import { FLATTENED_PRODUCTS } from '../utils/const'

const ProductDetailHero = styled(Section)`
  display: flex;

  & > div {
    flex: 1;

    &:last-child {
      margin-left: 1rem;
    }

    .image-gallery-right-nav,
    .image-gallery-left-nav {
      font-size: 2rem;

      &::before {
        text-shadow: 0 1px 2px #667;
      }
    }

    .image-gallery-fullscreen-button {
      &::before {
        font-size: 1.7rem;
      }
    }
  }

  ${media.forSmallOnly`
    flex-direction: column;

      & > div {
        flex: 1;

        img {
          padding: 0;
        }
      }
  `}
`

const StyledVideo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0 2rem;
`

export const ProductDetails = ({ productCategoryId }) => {
  const productObjs = FLATTENED_PRODUCTS.filter(
    p => p.productCategoryId === productCategoryId,
  )
  const productSubcategories = productObjs.filter(p => p.product)
  const productCategory = productObjs[0]

  return (
    <PageWrapper>
      <ProductDetailHero>
        <div>
          <ImageGallery
            thumbnailPosition={'left'}
            showPlayButton={false}
            items={productCategory.images.map(i => ({
              original: i,
              thumbnail: i,
            }))}
          />
        </div>
        <div>
          <h1>{productCategory.productCategory}</h1>
          <p>{productCategory.text}</p>
        </div>
      </ProductDetailHero>
      {productCategory.video ? (
        <StyledVideo>
          <iframe
            width="560"
            height="315"
            src={productCategory.video}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </StyledVideo>
      ) : null}

      {productSubcategories.length > 0 ? (
        <Section lightBackground header={'Variants'}>
          {productSubcategories.map(({ image, product, divisions, text }) => (
            <ProductCard
              key={product}
              image={image}
              product={product}
              divisions={divisions}
            >
              <p>{text}</p>
            </ProductCard>
          ))}
        </Section>
      ) : null}
    </PageWrapper>
  )
}
