import React from 'react'
import groupBy from 'lodash.groupby'
import forIn from 'lodash.forin'
import { PATENT_CATEGORIES } from '../utils/const'
import styled from 'styled-components'
import { media } from '@caldera-digital/theme'

const StyledTable = styled.table`
  max-width: 800px;
  font-size: 16px;
  margin: 0 auto;

  ${media.forSmallOnly`
    font-size: 14px;
  `}
`

const StyledCategoryRow = styled.tr`
  text-align: center;
  background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.white};

  td {
    padding: 1rem 0;
  }
`
const StyledPatentTableHeader = styled.tr`
  td {
    padding: 5px;
  }
`

const StyledPatentRow = styled.tr`
  td {
    padding: 2px 5px;
  }
`

const StyledPatentFootnote = styled.tr`
  font-size: 14px;
  text-align: right;

  td {
    padding: 5px;
  }
`

// TODO: If order isn't correct then need to add an order prop to in fixtures.json
export const PatentTable = ({ patents = [] }) => {
  const patentsByCategory = groupBy(patents, patent => patent.category)

  const hydratedPatentCategories = []
  forIn(patentsByCategory, (value, key) => {
    const foundPatent = PATENT_CATEGORIES.find(p => p.key === key)

    if (!foundPatent) {
      console.warn(`Patent not found for ${key}.`)
      return
    }

    foundPatent.patents = value
    hydratedPatentCategories.push(foundPatent)
  })

  return (
    <div>
      <StyledTable>
        <tbody>
          {hydratedPatentCategories.map(category => {
            return (
              <>
                <StyledCategoryRow>
                  <td colspan="3">{category.name}</td>
                </StyledCategoryRow>
                <StyledPatentTableHeader>
                  <td>
                    <b>Part Number</b>
                  </td>
                  <td>
                    <b>Description</b>
                  </td>
                  <td>
                    <b>Patents</b>
                  </td>
                </StyledPatentTableHeader>
                {category.patents.map(patent => {
                  return (
                    <>
                      <StyledPatentRow>
                        <td>{patent.partNumber}</td>
                        <td>{patent.name}</td>
                        <td>{patent.description}</td>
                      </StyledPatentRow>
                    </>
                  )
                })}
                {category.footnote && (
                  <StyledPatentFootnote>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>
                      <i>{category.footnote}</i>
                    </td>
                  </StyledPatentFootnote>
                )}
              </>
            )
          })}
        </tbody>
      </StyledTable>
    </div>
  )
}
