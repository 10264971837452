import React from 'react'
import styled from 'styled-components'
import { media } from '@caldera-digital/theme'
import { Section, LinkButton, PageWrapper } from '../components'

const ThankYouPage = styled(Section)`
  h1 {
    text-align: center;
    color: ${props => props.theme.primaryColor};
    font-size: 3.5rem;
  }

  p {
    color: ${props => props.theme.secondaryColor};
    text-align: center;
    font-size: 1.75rem;
  }

  a {
    margin: 0 auto;
    margin-top: 4rem;
    background-color: ${props => props.theme.primaryColor};
    width: 30%;
    text-align: center;

    ${media.forSmallMediumOnly`
      width: 40%;
    `}
  }
`

export const ThankYou = () => {
  return (
    <PageWrapper>
      <ThankYouPage>
        <h1>Thank you</h1>
        <p>We've received your message and will be in contact soon.</p>
        <LinkButton to="/">Back to Home</LinkButton>
      </ThankYouPage>
    </PageWrapper>
  )
}
