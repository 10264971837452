import React from 'react'
import styled, { withTheme } from 'styled-components'
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import { PRODUCT_LINES } from '../utils/const'
import { CoreValueItem } from './CoreValueCollection'
import { media } from '@caldera-digital/theme'
import { ReactComponent as Arrow } from '../assets/arrow.svg'

const ProductsCarouselContainer = styled.div`
  ${media.forLargeUp`
    .productItemsDesktop {
      display: block;
      display: flex;
      flex: 1;
    }

    .BrainhubCarousel {
      display: none;
    }
  `}

  ${media.forSmallMediumOnly`
    .productItemsDesktop {
      display: none;
    }

    .BrainhubCarousel {
      display: flex;
    }
  `}
`

export const ProductItem = styled(CoreValueItem)`
  width: 100%;
  min-height: 500px;

  ${media.forSmallMediumOnly`
    min-height: 400px;
  `}
`

const CarouselArrowContainer = styled.div`
  svg {
    fill: ${props => props.theme.secondaryColor};
    cursor: pointer;
  }
`

export const CarouselArrow = ({ flipped = false }) => {
  const style = {}
  if (flipped) style.transform = 'rotate(180deg)'

  return (
    <CarouselArrowContainer>
      <Arrow style={style} />
    </CarouselArrowContainer>
  )
}

const ProductsCarouselComponent = () => {
  return (
    <ProductsCarouselContainer>
      <div className="productItemsDesktop">
        {PRODUCT_LINES.map(product => (
          <ProductItem value={product} key={product.header} />
        ))}
      </div>
      <Carousel
        centered
        infinite
        slidesPerPage={3}
        breakpoints={{
          991: {
            slidesPerPage: 1,
            addArrowClickHandler: true,
            clickToChange: true,
            arrowLeft: <CarouselArrow flipped />,
            arrowRight: <CarouselArrow />,
          },
        }}
      >
        {PRODUCT_LINES.map(product => (
          <ProductItem value={product} key={product.header} />
        ))}
      </Carousel>
    </ProductsCarouselContainer>
  )
}

export const ProductsCarousel = withTheme(ProductsCarouselComponent)
