/**
 * IMPORTANT! IF YOU ADD, REMOVE, OR UPDATE ANY OF THE FORM FIELDS YOU HAVE TO UPDATE THE FORM IN
 * /PUBLIC/INDEX.HTML TO BE THE SAME. OTHERWISE, NETLIFY CAN'T PICK UP ON THE SUBMISSION BECAUSE THIS IS A
 * SINGLE PAGE APPLICATION.
 */
import React from 'react'
import styled from 'styled-components'
import { Section, Button, Input, Select, PageWrapper } from '../components'
import { Formik } from 'formik'
import * as yup from 'yup'
import ContactUsImage from '../assets/contact-us-image.png'
import { media } from '@caldera-digital/theme'
import { DIVISIONS, FLATTENED_PRODUCTS } from '../utils/const'
import sortBy from 'lodash.sortby'

const DEFAULT_FORM_NAME = 'contact'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const filteredProducts = FLATTENED_PRODUCTS.filter(p => !!p.product)

const CONTACT_FORM_BREAKPOINT = '790px'
const ContactContainer = styled(Section)`
  display: flex;

  > * {
    width: 50%;

    &:first-child {
      margin-right: 0.5rem;
    }
    &:last-child {
      margin-left: 0.5rem;
    }
  }

  @media (max-width: ${CONTACT_FORM_BREAKPOINT}) {
    flex-direction: column;

    > * {
      width: 100%;

      &:first-child {
        margin: 0 auto;
      }
      &:last-child {
        margin: 0 auto;
      }
    }
  }
`

const LeftColumn = styled.div`
  h1 {
    font-size: 3.5rem;
    color: ${props => props.theme.primaryColor};
  }

  p {
    color: ${props => props.theme.secondaryColor};
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  img {
    object-fit: contain;
    font-family: 'object-fit: contain;';
    width: 80%;
    display: block;
    margin: 0 auto;
  }

  > * {
    text-align: center;
  }

  @media (max-width: ${CONTACT_FORM_BREAKPOINT}) {
    img {
      display: none;
    }
  }

  ${media.forSmallOnly`
    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.25rem;
    }
  `}
`
const RightColumn = styled.div`
  max-width: 570px;

  form {
    display: flex;
    flex-direction: column;

    button {
      align-self: center;
    }
  }
`

const createSubjectLine = values => {
  let value = `Form submission from ${values['form-name']} form: ${values.name}`

  if (values.product) {
    value += ` XX ${values.product} XX`
  }

  return value
}

/**
 * 🚨 IMPORTANT! If you add form fields here then you need to go to `index.html` and add the associated field there because of
 * how netlify interprets forms in single page apps.
 */
export const Contact = ({ navigate }) => {
  const [loading, setLoading] = React.useState(false)

  return (
    <PageWrapper>
      <ContactContainer>
        <LeftColumn>
          <h1>Contact Us</h1>
          <p>We would love to hear from you!</p>
          <img src={ContactUsImage} alt="Nuts and bolts offered by CAM" />
        </LeftColumn>
        <RightColumn>
          <Formik
            initialValues={{
              'form-name': DEFAULT_FORM_NAME,
              name: '',
              email: '',
              phone: '',
              company: '',
              whoToContact: '',
              product: '',
              text: '',
            }}
            validationSchema={yup.object().shape({
              'form-name': yup
                .string()
                .when('whoToContact', {
                  is: val => val === 'Human Resources',
                  then: yup
                    .string()
                    .notOneOf([DEFAULT_FORM_NAME], 'Field is required.'), // contact is the default form name and must be changed if HR selected
                  otherwise: yup.string().nullable(),
                })
                .required('Form name is a required field.'),
              name: yup.string().required('Name is a required field.'),
              product: yup.string().when('whoToContact', {
                is: val => val === 'Sales',
                then: yup.string().required('Product is a required field.'),
                otherwise: yup.string().nullable(),
              }),
              email: yup
                .string()
                .required('Email is a required field.')
                .email('Must be a valid email address.'),
              phone: yup.string('Phone is a required field.'),
              company: yup.string().required('Company is a required field.'),
              whoToContact: yup.string().required('Field is required.'),
              text: yup.string().required('Field is required.'),
            })}
            onSubmit={values => {
              setLoading(true)

              // Will only work when deployed via Netlify on prod
              fetch('/', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: encode({ ...values, subject: createSubjectLine(values) }),
              })
                .then(() => navigate('/thank-you'))
                // eslint-disable-next-line no-alert
                .catch(error => alert(error))
            }}
          >
            {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isValid,
            }) => {
              return (
                <form
                  onSubmit={handleSubmit}
                  data-netlify="true"
                  // Send HR emails to another email in Netlify. We use this name to create a different form.
                  name={values['form-name']}
                  data-netlify-honeypot="bot-field"
                >
                  <Input
                    name="name"
                    placeholder="Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  <div hidden>
                    <label>
                      Don’t fill this out:{' '}
                      <input name="bot-field" onChange={handleChange} />
                    </label>
                  </div>
                  <Input
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  <Input
                    name="phone"
                    placeholder="Phone (Optional)"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                  <Input
                    name="company"
                    placeholder="Company"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.company}
                  />
                  <Select
                    onChange={e => {
                      setFieldValue('whoToContact', e.target.value)
                      setFieldValue('form-name', DEFAULT_FORM_NAME)
                      setFieldValue('product', '')
                    }}
                    onBlur={handleBlur}
                    value={values.whoToContact}
                    name="whoToContact"
                    options={[
                      {
                        value: '',
                        text: 'Tell us about you, so we can direct your message',
                      },
                      {
                        value: 'Human Resources',
                        text: 'I’d like to speak with Human Resources',
                      },
                      {
                        value: 'Supply Chain',
                        text: 'I’m a current/potential supplier',
                      },
                      {
                        value: 'Sales',
                        text: 'I’m a current/potential customer',
                      },
                      {
                        value: 'Other',
                        text: 'Other',
                      },
                    ]}
                  />
                  {values.whoToContact === 'Sales' && (
                    <Select
                      onChange={e => setFieldValue('product', e.target.value)}
                      onBlur={handleBlur}
                      value={values.product}
                      name="product"
                      options={[
                        {
                          value: '',
                          text: 'Which product?',
                        },
                        ...sortBy(filteredProducts, 'product').map(product => ({
                          value: product.product,
                          text: product.product,
                        })),
                        {
                          value: 'Other',
                          text: 'Other',
                        },
                      ]}
                    />
                  )}
                  {values.whoToContact === 'Human Resources' && (
                    <Select
                      onChange={e => setFieldValue('form-name', e.target.value)}
                      onBlur={handleBlur}
                      value={values.brand}
                      name="form-name"
                      options={[
                        {
                          value: '',
                          text: 'Which brand?',
                        },
                        {
                          value: 'hr-contact',
                          text: 'CAM',
                        },
                        ...DIVISIONS.map(division => ({
                          value: division.formName,
                          text: division.name,
                        })),
                      ]}
                    />
                  )}

                  <Input
                    name="text"
                    placeholder="How can we help?"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    element="textarea"
                    value={values.text}
                  />
                  <Button disabled={!isValid} type="submit" loading={loading}>
                    Submit
                  </Button>
                </form>
              )
            }}
          </Formik>
        </RightColumn>
      </ContactContainer>
    </PageWrapper>
  )
}
