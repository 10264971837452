import React from 'react'
import {
  Home,
  Contact,
  WhoWeAre,
  ThankYou,
  JoinOurTeam,
  Products,
  Resources,
  NotFound,
  ProductDetails,
  History,
  Division,
} from './pages'
import { ScrollToTop, NavBar, FooterLinks } from './components'
import { Router } from '@reach/router'
import { GlobalStyle } from '@caldera-digital/theme'
import { DIVISIONS } from './utils/const'

export const App = () => {
  return (
    <>
      {/* This doesn't render something to the DOM, it just injects global styles for styled-components */}
      <GlobalStyle />
      <NavBar />
      <Router primary={false}>
        <ScrollToTop path="/">
          <Home path="/" />
          <Contact path="contact-us" />
          <ThankYou path="thank-you" />
          <WhoWeAre path="who-we-are" />
          <JoinOurTeam path="careers" />
          <Products path="products" />
          <Resources path="resources" />
          <ProductDetails path="products/:productLineId/:productCategoryId" />
          <History path="history/:divisionSlug" />
          {DIVISIONS.map(d => (
            <Division key={d.key} path={d.key} division={d} />
          ))}
          <NotFound default />
        </ScrollToTop>
      </Router>
      <FooterLinks
        footerLinks={[
          { text: 'Who We Are', route: '/who-we-are' },
          { text: 'Our Products', route: '/products' },
          { text: 'Careers', route: '/careers' },
          { text: 'Contact Us', route: '/contact-us' },
          { text: 'Resources', route: '/resources' },
          {
            text: 'Privacy Policy',
            href: 'https://www.stanleyblackanddecker.com/privacy-policy',
          },
        ]}
      />
    </>
  )
}
