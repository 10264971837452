import React from 'react'
import {
  Hero,
  Section,
  CoreValueCollection,
  PowerStatement,
  NormalSection,
  // ImageSection,
  PageWrapper,
  BrandsCarousel,
} from '../components'
import GangChannel from '../assets/valves.jpg'
import styled from 'styled-components'
// import Background from '../assets/background-1.png'
// import CollaborationImage from '../assets/collaboration.jpg'

const CoreValues = styled(Section)`
  > p {
    max-width: 835px;
    margin: 0 auto 3rem;
    color: ${props => props.theme.grayText};
    text-align: center;
  }
`

export const WhoWeAre = () => {
  return (
    <PageWrapper>
      <Hero
        backgroundImage={GangChannel}
        renderHeroText={() => (
          <>
            <Hero.Header>Who we are</Hero.Header>
            <Hero.Text>
              At CAM, we are committed to delivering superior value to all our
              stakeholders by being a leading innovator and strategic partner in
              the supply of aerospace components.
            </Hero.Text>
          </>
        )}
      />
      <PowerStatement lightBackground>
        <h1>DELIVERING VALUE TOGETHER</h1>
        <p>
          We create value with high quality parts and a transactional
          experience. We are the aerospace partner you envision. Our success is
          linked with the success of our customers. We bring value to our
          customers by being a fast acting, flexible, and striving organization.
        </p>
      </PowerStatement>
      <NormalSection header="Our Mission & Vision">
        <p>
          At CAM, we are committed to delivering superior value to all our
          stakeholders by being a leading innovator and strategic partner in the
          supply of aerospace components. We believe our customers’ success, is
          our success.
        </p>
      </NormalSection>

      <CoreValues lightBackground header="Our Core Values">
        <p>
          We are relentless in the pursuit of Excellence in everything we do.
          This includes our dedication to the areas of Quality, Lean and
          Innovation.
        </p>
        <CoreValueCollection />
      </CoreValues>
      {/* <ImageSection header="Our Strategy" bottomBackgroundImage={Background}>
        <div>
          <p>
            We create that value not only in the high quality parts we produce
            but in the entire transactional experience. We are creating the
            aerospace partner you always envisioned. We understand that our
            success in linked to our Customer’s success. We have created an
            organization that is fast acting, flexible and constantly striving
            bring our customers value.
          </p>
          <img src={CollaborationImage} alt="CAM Collaboration" />
        </div>
      </ImageSection> */}
      <Section header="Our Brands" lightBackground>
        <BrandsCarousel showDetails />
      </Section>
    </PageWrapper>
  )
}
