import React from 'react'
import { Nav } from '@caldera-digital/nav'
import { ReactComponent as CAMLogo } from '../assets/cam-logo.svg'
import { Link, Location } from '@reach/router'
import styled from 'styled-components'
import { media } from '@caldera-digital/theme'

const LOGO_SPACING = '14px'
export const ANNOUNCEMENT_HEIGHT = '0px'
const LogoContainer = styled(Link)`
  width: 100px;

  svg {
    height: calc(${props => props.theme.desktopHeaderHeight} - ${LOGO_SPACING});

    ${media.forSmallOnly`
      height: calc(${props =>
        props.theme.mobileHeaderHeight} - ${LOGO_SPACING});
    `};
  }

  ${media.forSmallMediumOnly`
    width: auto;
  `};
`

const StyledNav = styled(Nav)`
  a {
    margin-right: 1.25rem;

    &:after {
      background-color: ${props => props.theme.secondaryColor} !important;
    }
  }

  ${media.forSmallMediumOnly`
    a {
      margin-right: 0.8rem;
    }
  `}

  ${media.forSmallOnly`
    position: fixed;
    top: ${ANNOUNCEMENT_HEIGHT};
    z-index: 50;
    width: 100%;

    a {
      margin: 0;
    }
  `}
`

const routeStyles = {
  textTransform: 'uppercase',
}

const createRoutes = location => [
  {
    route: '/who-we-are',
    text: 'Who We Are',
    as: Link,
    style: routeStyles,
    get selected() {
      return this.route === location.pathname
    },
  },
  {
    route: '/products',
    text: 'Our Products',
    as: Link,
    style: routeStyles,
    get selected() {
      return this.route === location.pathname
    },
  },
  {
    route: '/careers',
    text: 'Join Our Team',
    as: Link,
    style: routeStyles,
    get selected() {
      return this.route === location.pathname
    },
  },
  {
    route: '/contact-us',
    text: 'Contact Us',
    as: Link,
    style: routeStyles,
    get selected() {
      return this.route === location.pathname
    },
  },
]

export const NavBar = () => {
  return (
    <Location>
      {({ location }) => (
        <>
          <StyledNav
            routes={createRoutes(location)}
            renderLeftSection={() => (
              <LogoContainer to="/">
                <CAMLogo />
              </LogoContainer>
            )}
          />
        </>
      )}
    </Location>
  )
}
