import React from 'react'
import { Link } from '@reach/router'
import styled, { css } from 'styled-components'
import { media } from '@caldera-digital/theme'

const Container = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.lightBackgroundColor};

  div {
    border-right: 1px solid ${props => props.theme.linkColor};
    padding: 0 1rem;
  }

  div:last-child {
    border-right: none;
  }

  ${media.forSmallOnly`
    min-height: 0;
    height: auto;
    flex-direction: column;
    padding: 2rem 0;

    div {
      height: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0.5rem;
      border-right: none;
    }
  `}
`

const FooterLink = css`
  font-size: 1.1rem;
  color: ${props => props.theme.linkColor};

  &:hover {
    color: ${props => props.theme.hoveredSecondaryColor};
  }

  &:active {
    color: ${props => props.theme.pressedSecondaryColor};
  }
`

const StyledFooterLink = styled(Link)`
  ${FooterLink}
`

const StyledFooterAnchor = styled.a`
  ${FooterLink}
`

export const FooterLinks = props => {
  return (
    <Container>
      {props.footerLinks.map(({ text, route, href }, i) => (
        <div key={i}>
          {route && <StyledFooterLink to={route}>{text}</StyledFooterLink>}
          {href && (
            <StyledFooterAnchor
              href={href}
              target="_blank"
              rel="noopener noreferrer"
            >
              {text}
            </StyledFooterAnchor>
          )}
        </div>
      ))}
    </Container>
  )
}
