import React from 'react'
import styled from 'styled-components'

import { getDivisionNameFromCode } from '../utils/utilityService'

const BREAKPOINT = '768px'
const CardContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.white};
  margin-bottom: 1rem;
  min-height: 7rem;
  padding: 2rem 1rem;

  img {
    max-width: 10rem;
    margin-right: 1rem;
  }

  @media (max-width: ${BREAKPOINT}) {
    flex-direction: column;
    padding: 2rem 1.5rem;

    img {
      max-width: 80%;
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
`

const CardContent = styled.div`
  border-left: 1px solid ${props => props.theme.linkColor};
  padding: 1rem 1.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    width: 100%;
  }

  p {
    color: ${props => props.theme.primaryColor};
    font-size: 0.95rem;
    margin-bottom: 0.5rem;
  }

  @media (max-width: ${BREAKPOINT}) {
    border-left: none;
    border-top: 1px solid ${props => props.theme.linkColor};
    padding-top: 2rem;
  }
`

const ProductName = styled.p`
  && {
    font-size: 1.25rem;
    font-weight: bolder;
    font-family: ${props => props.theme.headerFont};
  }
`

const ProductInfo = styled.p`
  && {
    font-size: 0.85rem;
    color: ${props => props.theme.secondaryColor};
  }
`

export const ProductCard = ({ image, product, divisions, children }) => {
  return (
    <CardContainer>
      <img src={`${process.env.PUBLIC_URL}${image}`} alt={product} />
      <CardContent>
        <div>
          <ProductName>{product}</ProductName>
          <ProductInfo>
            Division{divisions.length > 1 ? 's' : ''}:{' '}
            {divisions.map(d => getDivisionNameFromCode(d))}
          </ProductInfo>
          <div>{children}</div>
        </div>
      </CardContent>
    </CardContainer>
  )
}
