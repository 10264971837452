import React, { useState } from 'react'
import {
  PageWrapper,
  Hero,
  Section,
  ProductItem,
  ProductGrid,
  CallToAction,
} from '../components'
import ProductsImage from '../assets/cam-ourproducts-hero.png'
import { PRODUCT_LINES, FLATTENED_PRODUCTS } from '../utils/const'
//import { DIVISIONS, DIVISIONS_COPY } from '../utils/const'
import { removeDuplicates } from '../utils/utilityService'
import styled, { withTheme } from 'styled-components'
import { scroller, Element } from 'react-scroll'
import { useMeasure } from '../hooks'
import Rodal from 'rodal'
import { Link } from '@reach/router'

export const MODAL_ACKNOWLEDGED_KEY = 'shopAnnouncementModalAcknowledged'

const StyledModal = styled(Rodal)`
  .rodal-dialog {
    width: 500px !important;
    height: 350px !important;
    padding: 2rem;

    @media (max-width: 600px) {
      width: 80% !important;
      height: 510px !important;
    }
  }
`

export const ProductsComponent = ({ theme, location }) => {
  const [showModal, setshowModal] = useState(false)
  const [bind, { width }] = useMeasure()

  React.useEffect(() => {
    setTimeout(() => {
      const acknowledgedModal = localStorage.getItem(MODAL_ACKNOWLEDGED_KEY)
      // String cause localstorage
      if (acknowledgedModal !== 'true') {
        setshowModal(true)
      }
    }, 3000)
  }, [setshowModal])

  React.useEffect(() => {
    scroller.scrollTo(location.hash, {
      smooth: true,
      offset: width > 680 ? 0 : -parseInt(theme.mobileHeaderHeight, 10),
      delay: 1000,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageWrapper {...bind}>
      <Hero
        backgroundImage={ProductsImage}
        renderHeroText={() => (
          <>
            <Hero.Header>Our Products</Hero.Header>
            <Hero.Text>
              At CAM we believe in delivering value in all that we do, for our
              customers, for our employees and for our stakeholders.
            </Hero.Text>
          </>
        )}
      />

      <CallToAction
        title="Shop with CAM"
        tagLine="Explore our engineered solutions on our new e-commerce platform."
        action="Shop CAM"
        href="https://shop.camaerospace.com/Page/Login"
      />

      {PRODUCT_LINES.map(pl => {
        const productsInLine = FLATTENED_PRODUCTS.filter(
          p => p.productLine === pl.header,
        )
        const uniqueProductsInLine = removeDuplicates(
          productsInLine,
          'productCategory',
        )

        return (
          <Element name={pl.hash} key={pl.header}>
            <Section lightBackground header={pl.header}>
              {pl.text()}
              <ProductGrid>
                {uniqueProductsInLine.map((p, index) => {
                  // Create object that component is expecting
                  return (
                    <ProductItem
                      value={{
                        ...p,
                        header: p.productCategory,
                        link: `${p.productLineId}/${p.productCategoryId}`,
                        text() {
                          return <p>{p.text}</p>
                        },
                      }}
                      key={index}
                    />
                  )
                })}
              </ProductGrid>
            </Section>
          </Element>
        )
      })}

      <StyledModal
        visible={showModal}
        onClose={() => {
          setshowModal(false)
          window.localStorage.setItem(MODAL_ACKNOWLEDGED_KEY, 'true')
        }}
      >
        <h2>Check out the new CAM shop</h2>
        <p>
          At CAM, we believe in delivering value in all that we do. To provide
          the best experience to our customers, we are pleased to introduce our
          online shop where you can view our products, look at your shopping
          history, place orders, and more.
        </p>
        <p>
          If you would like access, please{' '}
          <Link to="/contact-us">contact us</Link> to set your account up. If
          you have any feedback or ideas on how we can improve please let us
          know!
        </p>
        <br />
        <a
          href="https://shop.camaerospace.com/Page/Login"
          onClick={() => {
            setshowModal(false)
            window.localStorage.setItem(MODAL_ACKNOWLEDGED_KEY, 'true')
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          Take me there
        </a>
      </StyledModal>
    </PageWrapper>
  )
}

export const Products = withTheme(ProductsComponent)
