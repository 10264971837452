import { media } from '@caldera-digital/theme'
import React from 'react'
import styled from 'styled-components'

import Background from '../assets/background-2.png'
import HeroImg from '../assets/cam-home-hero.jpg'
import { ReactComponent as DeliveringValueTogether } from '../assets/delivering-value-together.svg'
import {
  BrandsCarousel,
  CallToAction,
  Hero,
  LinkButton,
  PageWrapper,
  ProductsCarousel,
  Section,
  StatsGrid,
} from '../components'

export const WelcomeSection = styled.div`
  display: flex;
  flex-direction: column;

  > a {
    margin: 0 auto;
    text-align: center;
    align-self: flex-start;
    margin-top: 2rem;
  }
`

export const WelcomeSectionContentRow = styled.div`
  display: flex;
  justify-content: center;

  > p {
    font-size: 1.5rem;
    margin: 0 2rem 4rem;
    text-align: center;
    max-width: 800px;
  }

  > img {
    object-fit: contain;
    font-family: 'object-fit: contain;';
    height: 100%;
  }

  ${media.forSmallOnly`
    > img {
      display: none;
    }

    > p {
      font-size: 1.25rem;
    }
  `}
`

const HeroTextContainer = styled.div`
  position: relative;
  /* Scale the svg with the height of the hero */

  svg {
    position: absolute;
    left: 0;
    top: 0;
  }

  > canvas {
    width: calc(${({ bounds: { width } }) => `${width}px`} / 3.75);
    display: block;
    visibility: hidden;
  }

  @media (max-width: 1000px) {
    width: calc(${({ bounds: { width } }) => `${width}px`} / 3.75);

    svg {
      position: relative;
      left: 0;
      top: 0;

      @media (max-height: 745px) and (min-width: 500px) {
        margin: 0 0 0 -3rem;
        width: 60%;
      }

      @media (max-height: 560px) and (min-width: 500px) {
        width: 40%;
      }

      @media (max-height: 445px) and (min-width: 500px) {
        width: 30%;
      }
    }

    > canvas {
      display: none;
    }
  }

  ${media.forSmallOnly`
    margin: 0 auto;
    width: calc(${({ bounds: { width } }) => `${width}px`} / 1.5);
  `}
`

export const Home = () => {
  return (
    <PageWrapper>
      <Hero
        backgroundImage={HeroImg}
        renderHeroText={bounds => (
          <HeroTextContainer bounds={bounds}>
            <DeliveringValueTogether />
            <canvas width="666.92" height="364.59" />
          </HeroTextContainer>
        )}
      />
      <Section
        lightBackground
        fluid
        sectionContainerStyle={{ padding: '8rem 0' }}
      >
        <WelcomeSection>
          <WelcomeSectionContentRow className="contentContainer">
            <p>
              At Consolidated Aerospace Manufacturing ("CAM"), we are committed
              to delivering superior value to all our stakeholders by being a
              leading innovator and strategic partner in the supply of aerospace
              components. We believe our customers’ success, is our success.
            </p>
          </WelcomeSectionContentRow>
          <LinkButton to="/who-we-are">Who We Are</LinkButton>
        </WelcomeSection>
      </Section>
      <Section header="Our Products" lightBackground>
        <ProductsCarousel />
      </Section>
      <Section header="Our Brands" lightBackground>
        <BrandsCarousel showDetails />
      </Section>
      <Section bottomBackgroundImage={Background}>
        <StatsGrid />
      </Section>
      <CallToAction
        title="Join Our Team"
        tagLine="Ready to work with us?"
        action="CAM Careers"
        linkPage="/careers"
      />
      <CallToAction
        lightBackground
        title="Contact Us"
        tagLine="Have any questions?"
        action="Reach Out"
        linkPage="/contact-us"
      />
    </PageWrapper>
  )
}
