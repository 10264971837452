import styled from 'styled-components'
import { media } from '@caldera-digital/theme'

export const ProductGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  justify-content: ${props => (props.centered ? 'center' : 'flex-start')};

  > div {
    width: calc(33% - 1rem);
  }

  ${media.forSmallMediumOnly`
    > div {
      width: calc(50% - 1rem);
    }
  `}

  ${media.forSmallOnly`
    > div {
      width: 100%;
    }
  `}
`
