import React from 'react'
import { Link } from '@reach/router'
import styled from 'styled-components'
import Carousel from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import { DIVISIONS } from '../utils/const'
import { CarouselArrow } from './ProductsCarousel'
import { hyphenate } from '../utils/utilityService'

/**
 * The carousel has an issue where it causes the animation to happen multiple times when it's at the end of an array
 * Here we make the duplicate the array and start people in the middle so they most likely never hit it
 */
const carouselCollection = [
  ...DIVISIONS,
  ...DIVISIONS,
  ...DIVISIONS,
  ...DIVISIONS,
]
const startingIndex = DIVISIONS.length * 2

const CardContainer = styled.div`
  background-color: ${props => props.theme.white};
  width: 100%;
  margin: 0 1rem;
  padding: 1rem;
  min-height: 140px;

  p {
    display: none;
  }
`

const StyledImage = styled.img`
  width: 100%;
  height: 100px;
  object-fit: contain;
  font-family: 'object-fit: contain;';
  display: inline-block;
  vertical-align: middle;
`

const BrandCarouselContainer = styled.div`
  .BrainhubCarouselItem {
    min-height: ${props => (props.showDetails ? '27.5rem' : '18.5rem')};

    ${CardContainer} {
      transition: transform 0.5s ease-in-out;
    }

    &--active {
      ${CardContainer} {
        transform: scale(1.75) !important;
        box-shadow: ${props =>
          '0px 0px 18px 0px ' + props.theme.borderBlurColor};
        z-index: 1;

        p {
          display: block;
          width: 100%;
          font-size: 0.5rem;
          margin-bottom: 0.55rem;

          a {
            overflow-wrap: break-word;
          }
        }
      }
    }
  }
`

export const BrandsCarousel = ({ showDetails = false }) => {
  const [currentIndex, setCurrentIndex] = React.useState(startingIndex)
  return (
    <BrandCarouselContainer showDetails={showDetails}>
      <Carousel
        centered
        infinite
        clickToChange
        slidesPerPage={5}
        arrowLeft={<CarouselArrow flipped />}
        arrowRight={<CarouselArrow />}
        addArrowClickHandler
        value={currentIndex}
        onChange={index => setCurrentIndex(index)}
        breakpoints={{
          991: {
            slidesPerPage: 3,
          },
          680: {
            slidesPerPage: 2,
          },
        }}
      >
        {carouselCollection.map(
          ({ logo, name, address, phone, email, key }) => (
            <CardContainer key={name}>
              {showDetails ? (
                <>
                  <Link to={`/${key}`}>
                    <StyledImage src={logo} alt={name} />
                  </Link>
                  <p>{address}</p>
                  <p>
                    Call: <a href={`tel:+${phone}`}>{phone}</a>
                  </p>
                  <p>
                    {email ? <a href={`mailto:${email}`}>{email}</a> : null}
                  </p>
                  <p style={{ margin: 0 }}>
                    <Link to={`/history/${hyphenate(name).toLowerCase()}`}>
                      View History
                    </Link>
                  </p>
                </>
              ) : (
                <Link to={`/${key}`}>
                  <StyledImage src={logo} alt={name} />
                </Link>
              )}
            </CardContainer>
          ),
        )}
      </Carousel>
    </BrandCarouselContainer>
  )
}
