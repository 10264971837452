import { DIVISIONS } from './const'

const ua = window.navigator.userAgent

export const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
  })
}

export const hyphenate = str => {
  return str.replace(/\s+/g, '-')
}

export const isIE = /MSIE|Trident/.test(ua)

export const getDivisionNameFromCode = code => {
  const division = DIVISIONS.find(d => d.key === code)

  if (!division) return ''

  return division.name
}
