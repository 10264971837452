import React from 'react'
import bristolLogo from '../assets/divisionLogos/bristol-logo.png'
import threeVFastenersLogo from '../assets/divisionLogos/3v-fasteners-logo.png'
import aerofitLogo from '../assets/divisionLogos/aerofit-logo.png'
import eapLogo from '../assets/divisionLogos/eap-logo.png'
import moellerLogo from '../assets/divisionLogos/moeller-logo.png'
import prikosAndBeckerLogo from '../assets/divisionLogos/prikos-and-becker-logo.png'
import qrpLogo from '../assets/divisionLogos/qrp-logo.png'
import vossLogo from '../assets/divisionLogos/voss-logo.png'
import EngineeredProducts from '../assets/cam-ep.jpg'
import Fittings from '../assets/fitting-flared.jpg'
import Fasteners from '../assets/fasteners.png'

// Division Hero Pages
import QRPHero from '../assets/cam-home-hero-qrp.jpg'
import MoellerHero from '../assets/cam-home-hero-moeller.jpg'
import VossHero from '../assets/cam-home-hero-voss.jpg'
import BristolHero from '../assets/cam-home-hero-bristol.jpg'
import ThreeVHero from '../assets/cam-home-hero-3v.jpg'
import AerofitHero from '../assets/cam-home-hero-aerofit.jpg'
import EAPHero from '../assets/cam-home-hero-eap.jpg'
import PBHero from '../assets/cam-home-hero-pnb.jpg'

import {
  products,
  divisions,
  patents,
  certifications,
  patentCategories,
} from './fixtures.json'

// Classic logos
import { ReactComponent as BristolLogoClassic } from '../assets/divisionLogosClassic/bristolCentered.svg'
import { ReactComponent as ThreeVLogoClassic } from '../assets/divisionLogosClassic/3VCentered.svg'
import { ReactComponent as AerofitLogoClassic } from '../assets/divisionLogosClassic/aerofitCentered.svg'
// import eapLogo from '../assets/divisionLogosClassic/eap-logo.png'
import { ReactComponent as MoellerLogoClassic } from '../assets/divisionLogosClassic/moellerLogo.svg'
// import prikosAndBeckerLogo from '../assets/divisionLogosClassic/prikos-and-becker-logo.png'
import { ReactComponent as QRPLogoClassic } from '../assets/divisionLogosClassic/QRPCentered.svg'
import { ReactComponent as VossLogoClassic } from '../assets/divisionLogosClassic/vossCentered.svg'

export const FLATTENED_PRODUCTS = products
export const PATENTS = patents
export const CERTIFICATIONS = certifications
export const PATENT_CATEGORIES = patentCategories

export const DIVISION_LOGOS = {
  bristol: {
    logo: bristolLogo,
    classicLogo: BristolLogoClassic,
    heroImage: BristolHero,
  },
  '3v-fasteners': {
    logo: threeVFastenersLogo,
    classicLogo: ThreeVLogoClassic,
    heroImage: ThreeVHero,
  },
  moeller: {
    logo: moellerLogo,
    classicLogo: MoellerLogoClassic,
    heroImage: MoellerHero,
  },
  aerofit: {
    logo: aerofitLogo,
    classicLogo: AerofitLogoClassic,
    heroImage: AerofitHero,
  },
  qrp: {
    logo: qrpLogo,
    classicLogo: QRPLogoClassic,
    heroImage: QRPHero,
  },
  eap: {
    logo: eapLogo,
    heroImage: EAPHero,
  },
  voss: {
    logo: vossLogo,
    classicLogo: VossLogoClassic,
    heroImage: VossHero,
  },
  'prikos-&-becker': {
    logo: prikosAndBeckerLogo,
    heroImage: PBHero,
  },
}

export const DIVISIONS = divisions.map(d => ({
  ...d,
  ...DIVISION_LOGOS[d.key],
}))

export const DIVISIONS_COPY = {
  bristol: {
    productText() {
      return <></>
    },
    aboutText() {
      return (
        <p>
          Bristol Industries is a worldwide leader in the manufacture of
          precision engineered nuts and fasteners for the aerospace industry.
          Headquartered in Brea, CA, Bristol offers over 4,000 active part
          numbers with an emphasis on OEM part standards and qualifications.
        </p>
      )
    },
    historyText() {
      return (
        <>
          <p>
            In 1942 Frank Klaus and Kenneth Reiner started K&R Manufacturing in
            a garage in Los Angeles, CA. The following year, they received a
            patent for the first lightweight self-locking nut.
          </p>

          <p>
            During the 1950’s K&R Manufacturing moved from Los Angeles to Pico
            Rivera, CA where they continued to manufacture nuts for the
            aerospace industry and began to be known as “Kaynar” after the two
            founders: “K&R.” They next moved to Fullerton, CA where they
            manufactured internally threaded nuts and gang channel.
          </p>

          <p>
            The following two decades were marked by continued growth and
            acquisition. After the partners sold Kaynar in the late 1960’s, Mr.
            Klaus in 1972 purchased Cold Forming Company in Fullerton, CA to
            make externally threaded bolts. Three years later he purchased
            Bristol Socket Screw in Waterbury, CT and promptly moved the
            facility to Fullerton, CA.
          </p>

          <p>
            In 1978 Bristol moved to the current Brea, CA location to
            manufacture airframe nuts and gang channel products for McDonnell
            Douglas on the F15 and F18 fighter programs. Subsequently Bristol
            manufactured airframe nuts for the C-17, DC-9, DC-10, KC-10, MD-11,
            MD-80 series and the MD-95 program. Soon after, this product line
            was expanded into Boeing Commercial for use on the 727, 737, 747,
            757 and 767 programs, as well as manufacturing fasteners for Airbus,
            General Electric, Pratt & Whitney, Rolls Royce, Snecma, Bombardier,
            Embraer, Lockheed Martin, Leonardo, and other major Airframe and
            Engine primes and subcontractors.
          </p>

          <p>
            Bristol Industries joined the Consolidated Aerospace Manufacturing
            (CAM) group of companies in 2014. Today, Bristol continues
            unequalled growth and success as a world leader of internally
            threaded nuts and gang channel.
          </p>
        </>
      )
    },
  },
  '3v-fasteners': {
    productText() {
      return <></>
    },
    aboutText() {
      return (
        <p>
          3V Fasteners is a leading manufacturer of externally threaded
          fasteners for the aerospace industry. Headquartered in Corona, CA, the
          company supplies most of the world’s leading aircraft companies with
          standard and special bolts and screws to mil-spec and customer special
          requirement.
        </p>
      )
    },
    historyText() {
      return (
        <>
          <p>
            In 1982 three brothers started a machine shop company in a 3,000
            square foot building in Corona, CA making socket head product.
            Daryl, Paul and Denny Ver Dorn named the company 3V Fasteners.
          </p>

          <p>
            In 1985 they began manufacturing fasteners for the computer disc
            drive industry and upgraded to 10,000 square feet of manufacturing
            space. In 1987 they expanded their products to the serve the
            aerospace industry.
          </p>

          <p>
            3V Fasteners continued to grow in both size and product diversity.
            In 1992 they moved to a larger 18,000 square feet facility, where
            they began manufacturing 12 Point Bolts and NAS6600 Series parts.
          </p>

          <p>
            During the late 1990’s and early 2000’s, 3V earned several industry
            accreditations and OEM customer approvals including: Titanium
            fasteners for Rolls Royce, initial Boeing approval on the BACS12
            A286 series and Spirit approval for the first Min/Max contract.
          </p>

          <p>
            Business growth, product expansion and capital equipment investment
            necessitated more facility upgrades. In 2004 3V Fasteners moved into
            a 38,000 square foot building on Reed Circle in Corona, CA and in
            2012 added a second building bringing the total manufacturing space
            to 64,000 square feet.
          </p>

          <p>
            3V Fasteners joined the Consolidated Aerospace Manufacturing (CAM)
            group of companies in 2014. Today, the company serves the global
            aerospace market out of a 77,000 square foot (7,200 square meter)
            plant in Corona, CA and continues our core commitment of striving
            for excellence in all aspects of sales, production, quality and
            delivery.
          </p>
        </>
      )
    },
  },
  moeller: {
    productText() {
      return <></>
    },
    aboutText() {
      return (
        <p>
          Moeller Manufacturing & Supply is a premier manufacturer of custom
          washers, spacers, nut plates and shims for the aerospace industry.
          Headquartered in Anaheim, CA, the company offers a broad portfolio of
          over 26,000 SKUs made from a wide spectrum of metallic, non-metallic,
          laminated and solid materials.
        </p>
      )
    },
    historyText() {
      return (
        <>
          <p>
            Moeller Manufacturing and Supply was founded in 1978 by Ron and
            Marsha Moller in their home garage. One year later they opened a
            1,100 square foot facility in Anaheim, CA with a single lathe
            machine.
          </p>

          <p>
            During the 1980’s, Moeller achieved high-growth by focusing on the
            military market, culminating in a facility expansion in 1991 to the
            1.3 acre headquarters in Anaheim, CA.
          </p>

          <p>
            Over the next decade, Moeller continued to grow and improved
            operations and through-put while increasing the customer base. In
            2014 the company expanded into a second building in Anaheim, CA.
          </p>

          <p>
            In 2018 Moeller Manufacturing & Supply joined the Consolidated
            Aerospace Manufacturing (CAM) group of companies.
          </p>
        </>
      )
    },
  },
  aerofit: {
    productText() {
      return <></>
    },
    aboutText() {
      return (
        <p>
          Aerofit designs and manufactures high and low pressure fluid fittings
          and fitting systems for aerospace, marine, nuclear and other critical
          industries. Headquartered in Fullerton, CA, the company is a market
          leader in both separable and permanents fittings with fully
          internalized engineering, manufacturing, processing and testing
          facilities.
        </p>
      )
    },
    historyText() {
      return (
        <>
          <p>
            Aerofit Products was founded in 1969 by Robert F. Peterjohn and
            began production in a 5,000 square feet (460 square meter) building.
            He started with a single Clausing engine lathe but soon added turret
            lathes, mills and other equipment. The first major product
            development was a metal port seal fitting used on the DC-10
            aircraft. Aerofit qualified and received their first long term
            contract in 1969.
          </p>

          <p>
            In March 1972 APT laboratories was established as a hydro-mechanical
            testing laboratory and in 1976 Aerofit Products received its first
            Boeing approval. In 1999 Aerofit acquired the Shape Memory Alloy
            (SMA) fitting system, which includes the copyright names CryoFit®,
            CryoFlare®, and CryoLive®. The Aerofit site grew into six buildings
            totaling 47,000 square feet (4,400 square meters).
          </p>

          <p>
            Aerofit Products and APT remained in the Peterjohn family until 2004
            when they were sold to Jordan Law and Dave Werner. In 2005 the new
            company was renamed Aerofit, Inc. and moved into a 67,000 square
            feet (6,200 square meters) modern building in Fullerton, CA.
          </p>

          <p>
            In 2013, Aerofit, LLC became part of Consolidated Aerospace
            Manufacturing (CAM). Today the company operates from two buildings
            totaling 92,000 square feet (8,500 square meters) and is a
            world-class provider of specialty components and related services.
          </p>
        </>
      )
    },
  },
  qrp: {
    productText() {
      return (
        <div>
          <h4>Part Descriptions Designed and Manufactured by QRP</h4>
          <ul>
            <li>
              Latches: Structural, Flush, Button, Hook, Pin, Channel, and
              Pressure
            </li>
            <li>Keepers: Mating part for hook latches</li>
            <li>
              Handles: For Doors, Enclosures, Pods, Access Hatches, Maintenance
              Hatches, and many more
            </li>
            <li>
              Pins: Quick release in a variety of sizes (grip length and
              diameter), handle types, shank materials, and number of locking
              elements (balls)
            </li>
            <li>
              Specialty Pins: Detent, Ground Handling/Safety, Cam Lever,
              Shackle, Marine, Autolock
            </li>
            <li>
              Receptacles: Used to mate to and help secure quick release pins
            </li>
            <li>
              Pin Accessories: Lanyards, Tabyards, Wire Rope Assemblies, Chain
              Assemblies, Streamers, Streamer Assemblies, Rings
            </li>
            <li>
              Adjustable Diameter Fasteners/Bolts: Utilize expanding
              segments which completely fill a hole. Used for high shear and
              tensile strength applications.
            </li>
            <li>
              Quick Change Track Locks: For quick and flexible removal and
              installation of aircraft interior stowage items, cabinets,
              doghouses, and cabin walls. Mates with a variety of seat tracks.
            </li>
            <li>Struts, Tie, and Hold-open Rods: Telescoping, Folding, Fixed</li>
            <li>Hinges: Gooseneck shape for doors and access panels</li>
			<li>Fasteners, Self-locking: Retainers for avionics trays 
				and electronic panels
			</li>
          </ul>
        </div>
      )
    },
    aboutText() {
      return (
        <p>
          With nearly half a century serving the aerospace industry, QRP
          specializes in the design and manufacture of the most innovative Quick
          Release and Reliable Fastening Systems to fit the most difficult
          industry applications. Headquartered in Berea, Ohio, the company now
          supports aerospace, defense, off-road/heavy vehicle, safety, state of
          the art audio systems, tooling, rigging, personal mobility, and marine
          applications with industry standard and qualified parts as well as
          specially designed and engineered units and assemblies. QRP is a
          problem solver for the industry.
        </p>
      )
    },
    historyText() {
      return (
        <>
          <p>
            In 1972, QRP was founded as Waldick Aerospace Devices on Long
            Island, NY and manufactured spring-loaded detent pins. Three
            partners purchased the company in 1983 and moved operations to Wall,
            NJ. They focused growth on positive locking quick release pins built
            to MS/NAS standards. In 1985, they relocated the plant to
            Williamsport, PA to manufacture quick release pins and associated
            accessories for the Defense and Commercial markets.
          </p>

          <p>
            In 1994, QRP moved their headquartered to
            Berea, Ohio and began a period of strong growth in the commercial
            aerospace market.
          </p>

          <p>
            QRP joined the Consolidated Aerospace Manufacturing (CAM) group of
            companies in 2014 with a continued focus on quick release mechanisms
            and designing structural latches for major aerospace OEMs. As a
            result of QRP’s innovative solutions, both Boeing and Airbus tapped
            QRP to provide unique latching solutions for their flagship
            aircraft: the Boeing 737MAX and the Airbus A320neo. 
          </p>
          <p>
            In 2020, CAM joined Stanley Black & Decker, Inc., a $14B revenue,
            purpose-driven industrial organization. It’s an exciting new era for
            QRP and its customers with the resources now being available to QRP
            by being part of the Stanley Black & Decker family.
          </p>
		  <p>
			In 2021, QRP completed a facility relocation to Berea, Ohio. Today, 
			QRP operates in a 108,000 square foot facility with Voss Industries, Inc.
			another CAM company. QRP and Voss specialize in design and 
			build-to-print engineered products. QRPs core products are 
			quick release pins, latches, struts, hold open rods,
			hinges, and adjustable diameter fasteners.
		  </p>
        </>
      )
    },
  },
  eap: {
    productText() {
      return <></>
    },
    aboutText() {
      return (
        <p>
          E.A. Patten is one of the world's leading tube manufacturers due to
          its dedication to technology. Our engineers have a long tradition of
          keeping up with increasing technology and taking advantage of the more
          than 60 years of experience with tube forming and sheet metal
          fabrication which we posses. Our facilities are cutting edge, using
          the latest techniques in Computer Aided Design Systems, CNC custom
          tube bending machines, EDM machines, vacuum induction brazing,
          pneumatic and hydraulic pressure testing and more.
        </p>
      )
    },
    historyText() {
      return (
        <>
          <p>
            E.A. Patten began in 1945 manufacturing the parts that helped get
            propeller driven aircraft off the ground. More than sixty years
            later, E.A.P. is now one of the world's leading tubular assembly
            companies, making the parts used in advanced aerospace applications.
            Our deep manufacturing knowledge combined with two state of the art
            facilities has helped pave the way for continued excellence and
            growth ready to serve any industry.
          </p>

          <p>
            We embrace change, learn and continue to grow while improving our
            craft. Since our earliest days as a machine shop and tube assembly
            facility, E.A.P. has demonstrated the flexibility, resourcefulness
            and integrity needed to fulfill the biggest commitments and meet any
            challenges that face us. As we advance through the 21st century, our
            commitment to aerospace excellence is only getting stronger.
          </p>

          <p>
            When you're looking for expert sheet metal fabricators, tube bending
            specialists or machined components, look no further than E.A.P. Our
            excellence is unsurpassed, and although we have grown, we still
            maintain the core values of a small business. We offer every client
            quality products and on time delivery with the respect they deserve.
          </p>
        </>
      )
    },
  },
  voss: {
    productText() {
      return <></>
    },
    aboutText() {
      return (
        <p>
          Voss manufactures high performance clamping, coupling components and
          fabricated subassemblies for the aerospace industry primarily.
          Headquartered in Cleveland, OH, the company’s success in the aerospace
          market stems from our innovative product development and
          problem-solving capabilities. Voss is skilled at providing customers
          with unique solutions and takes a project from concept to design to
          product development. Extensive life cycle testing and analysis
          culminates in world-class production and has positioned Voss as a
          leading supplier worldwide.
        </p>
      )
    },
    historyText() {
      return (
        <>
          <p>
            In 1957 William J. Voss founded Voss Industries as a sole
            proprietorship to manufacture heavy-duty clamps and V-band
            couplings. During the 1960’s, Voss developed a line of V-band
            couplings to meet performance requirements of MIL-C- 27536, plus
            MS27116 versions of high temp applications to 1500 o F (815.56 o C)
            in air and defense applications. In the 1970’s the company supported
            applications including diesel & locomotive engines, excavation
            equipment, and ducting, filtration and processing systems.
          </p>

          <p>
            During the 1980’s, Boeing selected Voss for field retrofit on all
            747 aircraft worldwide and our engineers joined SAE G-3A
            Subcommittee governing new bleed-air joints to finalize Aerospace
            Standard AS1895. Voss became the first manufacturer to successfully
            complete performance tests of its rigid bleed air joints to newly
            issued Aeronautical Standard AS1895. Meanwhile, the company designed
            our first bulge forming machine and produced sheet metal flanges for
            Boeing while NASA selected Voss to produce 12 foot diameter
            retaining bands for redesigned Space Shuttle rocket booster joints.
          </p>

          <p>
            In the 1990’s, Voss attained Boeing D1-9000 Advanced Quality System
            approval to participate in Boeing 777 program and received US and
            French patents for rigid coupling ‘foolproof’ safety latch. In the
            2000’s Voss became the first and only qualified manufacturer listed
            on PRI-QPL- AS5355 and received the “Excellence in Export” Award
            from Ohio Governor Taft.
          </p>

          <p>
            In 2014 Voss Industries joined the Consolidated Aerospace
            Manufacturing (CAM) group of companies. Today Voss continues to some
            of the world’s most remarkable engineering successes.
          </p>
        </>
      )
    },
  },
  'prikos-&-becker': {
    productText() {
      return <></>
    },
    aboutText() {
      return
    },
    historyText() {
      return (
        <>
          <p>
            Since 1954, Prikos & Becker has specialized in manufacturing the
            highest quality products requiring complex assembly and metal
            fabrication primarily for the aerospace industry. We have expertise
            in a wide range of manufacturing capabilities –including stamping,
            forming, machining, spot welding, continuous seam welding, laser
            cutting & laser welding – which allows our team to fulfill complex
            manufacturing needs for OEMs and first tier suppliers. Other
            operations include staking, orbital riveting, PEM inserting,
            customized packaging, and handling.
          </p>

          <p>
            Prikos & Becker has specific expertise in manufacturing heat shields
            with the exacting standards required in multiple aerospace
            applications. A particular core competency is the manufacture and
            assembly of highly precise heat shields in a variety of sizes for
            aircraft braking systems.
          </p>

          <p>
            Our parts and sub-assemblies are installed on all major commercial
            and military aircraft platforms from world leading companies
            including Boeing, Airbus, Bombadier, Embraer, Falcon, Fokker,
            Gulfstream, and Lockheed-Martin.
          </p>

          <p>
            With the latest AS 9100 and ISO 9001 quality management
            certifications, Prikos & Becker is a qualified direct supplier for a
            number of world-leading aerospace industry manufacturers.
          </p>
        </>
      )
    },
  },
}

export const PRODUCT_LINES = [
  {
    image: Fittings,
    header: 'Fittings',
    hash: '#fittings',
    text() {
      return (
        <p>
          We design and manufacture high and low pressure fluid fittings and
          fitting systems for the aerospace, marine, nuclear, and other critical
          industries. We provide individualized customer service from in-house
          and on-site technical support to application evaluation and product
          design.
        </p>
      )
    },
    link: '/products',
  },
  {
    image: EngineeredProducts,
    header: 'Engineered Products',
    hash: '#engineered-products',
    text() {
      return (
        <p>
          A leading manufacturing solutions provider of complex sheet metal,
          machined solutions, complex tube assemblies, latches and quick release
          pins. We provide solutions to engineering challenges across a wide
          range of products. CAM has a staff of world-leading design &
          applications engineers work closely with customers in maximizing
          application performance, utilizing the unique technologies across our
          manufacturing locations.
        </p>
      )
    },
    link: '/products',
  },
  {
    image: Fasteners,
    header: 'Fasteners',
    hash: '#fasteners',
    text() {
      return (
        <p>
          A global leader in the manufacture of aerospace and aircraft OEM Self
          Locking Nuts, Gang Channel Nuts, Standard and Special bolts, Screws,
          Custom Washers, Spacers and Shims.
        </p>
      )
    },
    link: '/products',
  },
]
