import React from 'react'
import styled from 'styled-components'
import { Container, media } from '@caldera-digital/theme'

const SectionContainer = styled.section`
  padding: 4rem 0;
  position: relative;
  background-color: ${props => {
    if (props.lightBackground) {
      return props.theme.lightBackgroundColor
    }
    if (props.backgroundColor) {
      return props.backgroundColor
    }

    return 'transparent'
  }};
  min-height: ${props => (props.bottomBackgroundImage ? '800px' : 'auto')};

  p {
    font-size: 1.25rem;
    color: ${props => props.theme.grayText};
  }

  ${media.forSmallMediumOnly`
    padding: 3rem 0;
    min-height: auto !important;

    p {
      font-size: 1rem;
    }
  `}

  ${media.forSmallMediumOnly`
    padding: 2rem 0;
  `}
`

const H2Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;

  h2 {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
`

const Line = styled.div`
  width: 180px;
  height: 4px;
  margin-right: 1rem;
  background-color: ${props => props.theme.secondaryColor};

  ${media.forSmallMediumOnly`
    width: 150px;
  `}

  ${media.forSmallOnly`
    width: 35px;
    margin-right: .5rem;
  `}
`

const BottomBackgroundImage = styled.div`
  position: absolute;
  bottom: 0%;
  width: 100%;
  height: 400px;
  opacity: 0.55;
  z-index: -1;
  background-size: cover;
  background-image: url(${props => props.bottomBackgroundImage});
  background-repeat: no-repeat;

  ${media.forSmallOnly`
    display: none;
  `}
`

export const H2 = ({ children }) => {
  return (
    <H2Container>
      <Line />
      <h2>{children}</h2>
    </H2Container>
  )
}

export const Section = ({
  children,
  fluid = false,
  lightBackground,
  header,
  backgroundColor,
  bottomBackgroundImage,
  renderSection,
  sectionContainerStyle = {},
  contentContainerStyle = {},
  sectionContainerClassName = '',
  // This is needed for styled components when we style the section component
  className,
}) => {
  return (
    <SectionContainer
      backgroundColor={backgroundColor}
      lightBackground={lightBackground}
      bottomBackgroundImage={bottomBackgroundImage}
      style={sectionContainerStyle}
      sectionContainerClassName={sectionContainerClassName}
    >
      {renderSection ? (
        renderSection()
      ) : (
        <>
          {header ? <H2>{header}</H2> : null}
          <Container
            fluid={fluid}
            style={contentContainerStyle}
            className={className}
          >
            {children}
          </Container>
        </>
      )}
      {bottomBackgroundImage ? (
        <BottomBackgroundImage bottomBackgroundImage={bottomBackgroundImage} />
      ) : null}
    </SectionContainer>
  )
}

export const PowerStatement = styled(Section)`
  max-width: 810px;

  h1,
  h2 {
    margin-bottom: 2rem;
    letter-spacing: 5px;
    text-transform: uppercase;
  }

  p {
    color: ${props => props.theme.defaultFontColor};
  }

  > * {
    text-align: center;
  }
`

export const TitleSection = styled(Section)`
  max-width: 870px;

  h1 {
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 2.5rem;
    color: ${props => props.theme.primaryColor};
  }

  > * {
    text-align: center;
  }

  ${media.forSmallOnly`
    h1 {
      font-size: 2rem;
    }
  `}
`

export const NormalSection = styled(Section)`
  li {
    margin-bottom: 0.25rem;
    color: ${props => props.theme.grayText};
  }
`

export const ImageSection = styled(Section)`
  > div {
    display: flex;

    /* IE11 needs flex: 1 on the left side content so we're assuming that there's only two columns and the first column can be any element */
    > *:first-child {
      flex: 1;
    }

    img {
      margin: 0 0 0 2rem;
      width: 33%;
      height: 25rem;
      object-fit: cover;
      font-family: 'object-fit: cover;';
    }

    ${media.forSmallMediumOnly`
      flex-direction: column;

      img {
        margin: 0;
        max-height: 18rem;
        height: auto;
        width: auto;
        margin: 1rem 0;
        object-position: 50% 0;
      }
    `}

    ${media.forSmallOnly`
      flex-direction: column-reverse;
    `}
  }
`
