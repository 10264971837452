import React from 'react'
import styled from 'styled-components'
import { PageWrapper, Section } from '../components'
import { DIVISIONS, DIVISIONS_COPY } from '../utils/const'
import { hyphenate } from '../utils/utilityService'
import Background from '../assets/background-1.png'
import { media } from '@caldera-digital/theme'

const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 4rem 0;
  position: relative;
  height: 375px;
  display: flex;
  justify-content: center;
  align-items: center;

  div::after {
    content: '';
    background: url(${Background});
    background-size: cover;
    opacity: 0.25;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }

  img,
  svg {
    max-height: 12rem;
    max-width: 55%;
    object-fit: contain;
  }

  ${media.forSmallOnly`
    height: 300px;

      img,
      svg {
        max-height: 12rem;
        max-width: 85%;
        object-fit: contain;
      }
  `}
`

const ContentContainer = styled.div`
  p {
    font-size: 1.15rem;
    line-height: 1.5rem;
    color: ${props => props.theme.primaryColor};
  }
`

export const History = ({ divisionSlug }) => {
  const { logo, name, classicLogo: ClassicLogo } = DIVISIONS.find(
    divisionObj => hyphenate(divisionObj.name).toLowerCase() === divisionSlug,
  )
  const historyContent = DIVISIONS_COPY[divisionSlug].historyText()

  return (
    <PageWrapper>
      <HeroContainer>
        <div />
        {ClassicLogo ? <ClassicLogo /> : <img src={logo} alt={name} />}
      </HeroContainer>
      {historyContent && (
        <Section header="History" lightBackground>
          <ContentContainer>{historyContent}</ContentContainer>
        </Section>
      )}
    </PageWrapper>
  )
}
