import React from 'react'
import styled from 'styled-components'
import { InputContainer, Error } from './Input'
import { ErrorMessage } from 'formik'

const TRIANGLE_SIZE = '6px'
const DropdownTriangle = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: ${TRIANGLE_SIZE} solid transparent;
  border-right: ${TRIANGLE_SIZE} solid transparent;
  border-top: ${TRIANGLE_SIZE} solid ${props => props.theme.primaryColor};
`

const CustomSelectContainer = styled.div`
  position: relative;

  &:hover {
    ${DropdownTriangle} {
      border-top-color: ${props => props.theme.secondaryColor};
    }
  }
`

const CustomSelect = styled.select`
  display: block;
  font-size: 1rem;
  font-family: ${props => props.theme.defaultFont};
  color: ${props => props.theme.primaryColor};
  line-height: 1.3;
  padding: 16px 0 13px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  /* Clear default styling */
  border: none;
  border-radius: 0;
  border-bottom: 2px solid ${props => props.theme.primaryColor};
  box-shadow: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: ${props => props.theme.white};
  margin-bottom: 6px;

  &::-ms-expand {
    display: none;
  }
  &:hover {
    border-color: ${props => props.theme.secondaryColor};
  }
  &:focus {
    border-color: ${props => props.theme.secondaryColor};
    color: #222;
    outline: none;
  }
  option {
    font-weight: normal;
  }
`

export const Select = ({ onChange, onBlur, value, options, name }) => {
  return (
    <InputContainer>
      <CustomSelectContainer>
        <CustomSelect
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          name={name}
        >
          {options.map(option => (
            <option value={option.value} key={option.value}>
              {option.text}
            </option>
          ))}
        </CustomSelect>
        <DropdownTriangle />
      </CustomSelectContainer>
      <ErrorMessage name={name}>{error => <Error>{error}</Error>}</ErrorMessage>
    </InputContainer>
  )
}
