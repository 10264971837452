import React from 'react'
import styled from 'styled-components'
import { media } from '@caldera-digital/theme'
import { Section, PageWrapper } from '../components'
import Image404 from '../assets/404.png'

const ErrorPage = styled(Section)`
  height: calc(100vh - ${props => props.theme.desktopHeaderHeight});
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  p {
    text-align: center;
    color: ${props => props.theme.primaryColor};
    font-size: 3.5rem;
    font-weight: 1000;
    margin-top: 2rem;
    ${media.forSmallOnly`
      font-size: 2rem;
      margin-top: 1.5rem;
    `}
  }
`

export const NotFound = () => {
  return (
    <PageWrapper>
      <ErrorPage sectionContainerStyle={{ padding: 0 }}>
        <img src={Image404} alt="404" />
        <p>Page Not Found!</p>
      </ErrorPage>
    </PageWrapper>
  )
}
