import React from 'react'
import styled from 'styled-components'
import PlaneImage from '../assets/plane.jpg'
import CollaborationImage from '../assets/collaboration.jpg'
import { media } from '@caldera-digital/theme'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.forSmallMediumOnly`
    flex-direction: column;
  `}
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  ${media.forSmallMediumOnly`
    width: 90%;
  `}
`

const Card = styled.div`
  display: flex;
  margin: 0.8rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${props => {
    if (props.primaryBackground) {
      return props.theme.primaryColor
    }
    if (props.secondaryBackground) {
      return props.theme.secondaryColor
    }
    return 'transparent'
  }};
  background-image: url(${props => props.backgroundImage});
  background-size: 100% auto;
  background-position: center;
  padding: ${props => (props.noPadding ? '0' : '1.3rem')};

  height: ${props => {
    if (props.backgroundImage) {
      return '7.2rem'
    }
    if (props.large) {
      return '11.78rem'
    }
    return '9.56rem'
  }};

  ${media.forSmallMediumOnly`
    margin: 0;
    height: 10rem;
    text-align: center;
  `}
`

const StyledText = styled.p`
  &&& {
    color: ${props => props.theme.white};
    font-size: 1.37rem;
    line-height: 1.95rem;
    margin: 0;
    width: 100%;
  }
`

const StyledHeader = styled.p`
  &&& {
    font-family: ${props => props.theme.headerFont};
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: ${props => {
      if (props.secondary) {
        return props.theme.secondaryColor
      }
      return props.theme.primaryColor
    }};
    font-size: ${props => {
      if (props.secondary) {
        return '2.45rem'
      }
      return '4.32rem'
    }};
  }
`

export const StatsGrid = () => {
  return (
    <Container>
      <CardsContainer>
        <Card noPadding>
          <StyledHeader>1,400+</StyledHeader>
          <StyledHeader secondary>Employees</StyledHeader>
        </Card>
        <Card secondaryBackground large>
          <StyledText>
            Investing in becoming the partner you always wanted.
          </StyledText>
        </Card>
        <Card backgroundImage={PlaneImage} />
      </CardsContainer>
      <CardsContainer>
        <Card noPadding>
          <StyledHeader>8</StyledHeader>
          <StyledHeader secondary>Divisions</StyledHeader>
        </Card>
        <Card primaryBackground>
          <StyledText>Founded in 2012, headquartered in Brea, CA.</StyledText>
        </Card>
        <Card secondaryBackground>
          <StyledText>Innovation and value delivered in every part.</StyledText>
        </Card>
      </CardsContainer>
      <CardsContainer>
        <Card noPadding>
          <StyledHeader>800k+</StyledHeader>
          <StyledHeader secondary>Square Feet</StyledHeader>
        </Card>
        <Card backgroundImage={CollaborationImage} />
        <Card primaryBackground large>
          <StyledText>
            Capable manufacturer of critical aerospace and defense components.
          </StyledText>
        </Card>
      </CardsContainer>
    </Container>
  )
}
