import React from 'react'
import {
  Hero,
  PowerStatement,
  TitleSection,
  NormalSection,
  CallToAction,
  ImageSection,
  PageWrapper,
} from '../components'
import CollaborationImage from '../assets/collaboration.jpg'
import ManAtComputer from '../assets/man-at-computer.jpg'
import PersonUsingSaw from '../assets/person-using-saw.jpg'
import Background from '../assets/background-1.png'
import Background2 from '../assets/background-2.png'

export const JoinOurTeam = () => {
  return (
    <PageWrapper>
      <Hero
        backgroundImage={CollaborationImage}
        renderHeroText={() => (
          <>
            <Hero.Header>Join our Team</Hero.Header>
            <Hero.Text>
              We are looking for skilled professionals with a passion for
              quality and excellence.
            </Hero.Text>
          </>
        )}
      />
      <CallToAction
        lightBackground
        title="Join Our Team"
        tagLine="Ready to work with us?"
        action="Search Jobs"
        href="https://sbdinc.wd1.myworkdayjobs.com/Stanley_Black_Decker_Career_Site"
      />
      <PowerStatement lightBackground>
        <h1>CONSOLIDATED AEROSPACE MANUFACTURING</h1>
        <p>
          At CAM, our people are the foundation for our success. We provide
          growth and career development opportunities at all locations across
          the United States. We are always seeking to hire talented,
          hard-working and committed individuals for roles in Operations,
          Engineering, Quality, Finance and other Support Roles.
        </p>
      </PowerStatement>
      <TitleSection>
        <h1>Employee Benefits</h1>
        <p>
          We are happy to provide a competitive benefits program that offers
          value to our employees and balances healthcare needs with longer-term
          needs like planning for retirement.
        </p>
      </TitleSection>
      <NormalSection header="Health & Wellness">
        <p>
          Our goal is for you and your family to be healthy! As such, we offer
          flexible, comprehensive coverage plans that allow you to decide the
          level and type of coverage that is best for you.
        </p>
        <p>
          Employees who work at least 30 hours per week have the opportunity to
          participate in a wide range of benefits:
        </p>
        <ul>
          <li>Medical, Dental, and Vision Insurance</li>
          <li>Group Life and AD&D Insurance</li>
          <li>Health Care and Dependent Care Flexible Spending Accounts</li>
          <li>Short and Long Term Disability Plans</li>
          <li>Employee Assistance Plan (EAP)</li>
          <li>Paid Time Off Holidays and Vacation</li>
          <li>Tuition Reimbursement</li>
        </ul>
      </NormalSection>
      <ImageSection
        header="Financial Security"
        bottomBackgroundImage={Background}
      >
        <div>
          <p>
            Feeling financially secure during our working years and into
            retirement is important to all of us. CAM provides a 401k retirement
            plan with a variety of investment options to help support your
            financial security today and in the future.
          </p>
          <img src={ManAtComputer} alt="Man at Computer" />
        </div>
      </ImageSection>

      <TitleSection>
        <h1>Additional Benefits</h1>
        <p>
          We believe in a work-life balance! We offer a variety of
          paid-time-off, sick leave, vacations, and paid holidays.
        </p>
      </TitleSection>
      <NormalSection header="Career Advancement">
        <p>
          Demonstrated performance will allow for career advancement through the
          many opportunities that arise across our operating units and within
          our many functional disciplines. A network of people, systems, and
          processes guide our team members in career advancement options.
        </p>
      </NormalSection>
      <ImageSection
        header="Equal Opportunity Employer"
        bottomBackgroundImage={Background2}
        sectionContainerStyle={{ minHeight: '900px' }}
      >
        <div>
          <div>
            <p>
              CAM is committed to providing equal employment opportunities to
              all employees and applicants without regard to race, ethnicity,
              religion, color, sex, gender, gender identity or expression,
              sexual orientation, national origin, ancestry, citizenship status,
              uniform service member and veteran status, marital status,
              pregnancy, age, protected medical condition, genetic information,
              disability or any other protected status in accordance with all
              applicable federal, state and local laws.
            </p>
            <p>
              We strongly encourage all qualified candidates to apply to our job
              openings.
            </p>
          </div>
          <img src={PersonUsingSaw} alt="Person Using Saw" />
        </div>
      </ImageSection>
    </PageWrapper>
  )
}
