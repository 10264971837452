import { media } from '@caldera-digital/theme'
import React from 'react'
import styled from 'styled-components'
import HeroImg from '../assets/cam-home-hero.jpg'
import {
  Hero,
  LinkButton,
  PageWrapper,
  ProductGrid,
  ProductItem,
  Section,
  PatentTable,
} from '../components'
import {
  CERTIFICATIONS,
  DIVISIONS_COPY,
  FLATTENED_PRODUCTS,
  PATENTS,
} from '../utils/const'
import { WelcomeSection, WelcomeSectionContentRow } from './Home'

const Logo = styled.img`
  width: 70%;
  height: 100%;
  object-fit: contain;
  font-family: 'object-fit: contain;';

  ${media.forSmallMediumOnly`
    width: 60%;
  `}

  ${media.forSmallOnly`
    display: block;
    margin: 0 auto;
    width: 80%;
  `}

  @media (max-height: 745px) and (min-width: 500px) {
    margin: 0 0 0 -3rem;
    width: 60%;
  }

  @media (max-height: 560px) and (min-width: 500px) {
    width: 40%;
  }

  @media (max-height: 445px) and (min-width: 500px) {
    width: 30%;
  }
`

const CertificationSection = styled.ul`
  li {
    margin-bottom: 0.5rem;
  }
`

const ContactItem = styled.p`
  && {
    font-size: 1rem;
  }
`

export const Division = ({
  division: { logo, name, key, address, phone, email, heroImage },
}) => {
  const aboutContent = DIVISIONS_COPY[key].aboutText()

  const productsForDivision = FLATTENED_PRODUCTS.filter(x =>
    x.divisions.includes(key),
  )
  const patentsForDivision = PATENTS.filter(x => x.divisions.includes(key))
  const certificationsForDivision = CERTIFICATIONS.filter(x =>
    x.divisions.includes(key),
  )
  const productContent = DIVISIONS_COPY[key].productText()

  return (
    <PageWrapper>
      <Hero
        backgroundImage={heroImage || HeroImg}
        renderHeroText={() => {
          return <Logo src={logo} alt={`${name} logo`} />
        }}
      />
      <Section
        lightBackground
        fluid
        sectionContainerStyle={{ padding: '8rem 0' }}
      >
        {aboutContent && (
          <WelcomeSection>
            <WelcomeSectionContentRow className="contentContainer">
              {aboutContent}
            </WelcomeSectionContentRow>
            <LinkButton to={`/history/${key}`}>Our History</LinkButton>
          </WelcomeSection>
        )}
      </Section>

      <Section lightBackground header="Our Products">
        <WelcomeSection>
          <WelcomeSectionContentRow className="contentContainer">
            {productContent}
          </WelcomeSectionContentRow>
        </WelcomeSection>
        <ProductGrid centered>
          {productsForDivision.length > 0
            ? productsForDivision.map((p, index) => (
                <ProductItem
                  value={{
                    ...p,
                    header: p.product,
                    link: `/products/${p.productLineId}/${p.productCategoryId}`,
                    text() {
                      return <p>{p.text}</p>
                    },
                  }}
                  key={index}
                />
              ))
            : 'No products.'}
        </ProductGrid>
      </Section>
      {patentsForDivision.length > 0 ? (
        <Section header="Patents">
          <PatentTable patents={patentsForDivision} />
        </Section>
      ) : null}
      {certificationsForDivision.length > 0 ? (
        <Section
          header="Certifications, Qualifications, and Capabilities"
          lightBackground
        >
          <CertificationSection>
            {certificationsForDivision.map((c, index) =>
              c.attachment ? (
                <li key={index}>
                  <a
                    href={`/assets/certifications/${c.attachment}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {c.name}
                  </a>
                </li>
              ) : (
                <li key={index}>{c.name}</li>
              ),
            )}
          </CertificationSection>
        </Section>
      ) : null}
      {/* {historyContent && (
        <Section header="History" lightBackground>
          <ContentContainer>{historyContent}</ContentContainer>
        </Section>
      )} */}
      <Section header="Contact">
        <ContactItem>{address}</ContactItem>
        <ContactItem>
          Call: <a href={`tel:+${phone}`}>{phone}</a>
        </ContactItem>
        {email ? (
          <ContactItem>
            <a href={`mailto:${email}`}>{email}</a>
          </ContactItem>
        ) : null}
      </Section>
    </PageWrapper>
  )
}
