import React from 'react'
import styled from 'styled-components'
import { media } from '@caldera-digital/theme'
import { LinkButton, HREFButton } from './Button'
import { Section } from './Section'

const CallToActionSection = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 400px;

  .ctaTitle {
    color: ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.headerFont};
    font-size: 3.3rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    padding: 1rem;
  }

  .ctaTagline {
    color: ${props => props.theme.secondaryColor};
    font-size: 2rem;
    margin-bottom: 2rem;
    padding: 0 1rem;
    text-align: center;
  }

  ${media.forSmallOnly`
    min-height: 300px;

    .ctaTitle {
      font-size: 2rem;
      margin-bottom: 0;
    }

    .ctaTagline {
      font-size: 1.5rem;
    }
  `}
`

export const CallToAction = ({
  lightBackground = false,
  title,
  tagLine,
  linkPage,
  href,
  action,
}) => {
  return (
    <CallToActionSection lightBackground={lightBackground}>
      <p className="ctaTitle">{title}</p>
      <p className="ctaTagline">{tagLine}</p>
      {href && <HREFButton href={href}>{action}</HREFButton>}
      {linkPage && <LinkButton to={linkPage}>{action}</LinkButton>}
    </CallToActionSection>
  )
}
