import React from 'react'
import { Section, PageWrapper } from '../components'
import styled from 'styled-components'

import ContactUsImage from '../assets/contact-us-image.png'
import { media } from '@caldera-digital/theme'
import SupplierPoTerms from '../assets/documents/Purchase_Order_Terms_and_Conditions_for_CAM_Suppliers.pdf'
import CustomerPoTerms from '../assets/documents/Purchase_Order_Terms_and_Conditions_for_CAM_Customers.pdf'
import TrafficPolicy from '../assets/documents/Supply_Chain_Transparency_Policy.pdf'
import corona from '../assets/documents/CoronavirusAnnouncement03102020.pdf'
import NonCommercialDFARS from '../assets/documents/FAR_DFARS_Flowdown - FAR Part 15 Non-Commercial Items.pdf'
import CommercialDFARS from '../assets/documents/FAR_DFARS_Flowdown - FAR Part 12 Commercial Items.pdf'
import Voss_QRP_Prikos_Annual_Price_Escalation from '../assets/documents/Voss_QRP_Prikos_Annual_Price_Escalation.pdf'
//import PrivacyPolicy from '../assets/documents/CAM_Privacy_Policy.pdf'

const resourceFiles = [
  {
    file: SupplierPoTerms,
    text: 'Purchase Order Terms and Conditions for CAM Suppliers (PDF)',
  },
  {
    file: CustomerPoTerms,
    text: 'Purchase Order Terms and Conditions for CAM Customers (PDF)',
  },
  {
    file: NonCommercialDFARS,
    text: 'FAR DFARS Flowdown - FAR Part 15 Non-Commercial Items (PDF)',
  },
  {
    file: CommercialDFARS,
    text: 'FAR DFARS Flowdown - FAR Part 12 Commercial Items (PDF)',
  },
  {
    file: Voss_QRP_Prikos_Annual_Price_Escalation,
    text: 'Voss, QRP, Prikos Annual Price Escalation (PDF)',
  },
  {
    file: TrafficPolicy,
    text: 'Supply Chain Transparency Policy (PDF)',
  },
  {
    file: corona,
    text: '2019-nCoV Announcement',
  },
  //{
  //    file: PrivacyPolicy,
  //text: 'Privacy Policy (PDF)',
  //},
]

const Container = styled(Section)`
  display: flex;

  div {
    img {
      object-fit: contain;
      font-family: 'object-fit: contain;';
      width: 80%;
      display: block;
      margin: 0 auto;
    }

    h1 {
      font-size: 3.5rem;
      color: ${props => props.theme.primaryColor};
      margin-bottom: 2rem;
    }

    a {
      display: block;
      color: ${props => props.theme.secondaryColor};
      font-size: 1.1rem;
      margin-bottom: 2rem;
      line-height: 1.3rem;
    }

    > * {
      text-align: center;
    }
  }

  ${media.forSmallMediumOnly`
    flex-direction: column;
    div {
      h1 {
      font-size: 2.5rem;
    }

    a {
      font-size: 1rem;
    }
    }
  `}
`

export const Resources = () => {
  return (
    <PageWrapper>
      <Container>
        <div>
          <h1>Resources</h1>
          {resourceFiles.map(({ file, text }) => (
            <a key={file} href={file} target="_blank" rel="noopener noreferrer">
              {text}
            </a>
          ))}
        </div>
        <div>
          <img src={ContactUsImage} alt="Nuts and bolts offered by CAM" />
        </div>
      </Container>
    </PageWrapper>
  )
}
