// Needs to be the first thing parsed in order to work
import './polyfills.js'
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from 'styled-components'
import {
  CalderaDigitalThemeProvider,
  CalderaDigitalThemeConsumer,
} from '@caldera-digital/theme'
import { theme as customTheme } from './style'

import 'rodal/lib/rodal.css'

import 'normalize.css'

/**
 * Essentially, we have a default theme that's used by Caldera components and a base. Passing in a theme
 * merges the two objects. Once that's merged, we also want to pass that directly into the style components
 * theme provider so we can use these properties throughout the app.
 */
ReactDOM.render(
  <CalderaDigitalThemeProvider theme={customTheme}>
    <CalderaDigitalThemeConsumer>
      {theme => (
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      )}
    </CalderaDigitalThemeConsumer>
  </CalderaDigitalThemeProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
