import { polyfill as promisePolyfill } from 'es6-promise'
import objectFitImages from 'object-fit-images'
import 'isomorphic-fetch'

// Core-js polyfills go here
import 'core-js/features/array/includes'
import 'core-js/features/object/assign'
import 'core-js/features/array/from'
import 'core-js/features/array/find'

objectFitImages()
promisePolyfill()
