import React from 'react'
import styled from 'styled-components'
import { media } from '@caldera-digital/theme'
import { ReactComponent as ArrowCircle } from '../assets/arrow-circle.svg'
import { Link } from '@reach/router'

const values = [
  {
    header: 'Excellence',
    text() {
      return (
        <p>
          We are relentless in the pursuit of excellence in everything we do. We
          are dedicated to an innovative, quality-focused, lean production
          system.
        </p>
      )
    },
  },
  {
    header: 'Integrity',
    text() {
      return <p>We will act with integrity and honesty in all that we do.</p>
    },
  },
  {
    header: 'Teamwork',
    text() {
      return <p>We will act as one company, one team.</p>
    },
  },
  {
    header: 'Respect',
    text() {
      return (
        <p>
          We always strive to treat everyone with fairness, dignity and respect.
        </p>
      )
    },
  },
  {
    header: 'Customer Focused',
    text() {
      return (
        <p>
          We are committed to partner with our customers and deliver a superior
          value and experience in every interaction.
        </p>
      )
    },
  },
  {
    header: 'Safety & Environment',
    text() {
      return (
        <p>
          We committed to maintain safe work places and to meet our
          environmental and community responsibilities.
        </p>
      )
    },
  },
]

const CoreValueCollectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0;
`

const CoreValueItemContainer = styled.div`
  padding: 1.5rem;
  margin: 0 0.5rem 1.5rem;
  width: calc(33% - 1rem);
  background-color: ${props => props.theme.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h3 {
    font-weight: bold;
    color: ${props => props.theme.primaryColor};
    text-align: center;
    font-size: 1.25rem;
  }

  .fancySpacer {
    height: 1px;
    background-color: ${props => props.theme.secondaryColor};
    margin: 1rem 0 1.5rem;
  }

  p {
    font-size: 1rem;
    color: ${props => props.theme.defaultFontColor};
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: contain;
    font-family: 'object-fit: contain;';
  }

  svg {
    display: block;
    margin: 2rem auto 0;
    text-align: center;
    height: 30px;
    width: 30px;
    stroke-width: 5;
  }

  ${media.forSmallMediumOnly`
    width: calc(50% - 1rem);
  `};

  ${media.forSmallOnly`
    width: 100%;

    p {
      text-align: center;
    }
  `}
`

export const CoreValueItem = ({
  value: { header, text, image, link, hash },
  className = '',
  style = {},
}) => {
  const to = link ? (hash ? `${link}${hash}` : link) : null
  return (
    <CoreValueItemContainer style={style} className={className}>
      <div>
        {image ? (
          to ? (
            <Link to={to}>
              <img alt={header} src={image} />
            </Link>
          ) : (
            <img alt={header} src={image} />
          )
        ) : null}
        {to ? (
          <Link to={to}>
            <h3>{header}</h3>
          </Link>
        ) : (
          <h3>{header}</h3>
        )}

        <div className="fancySpacer" />
        {text()}
      </div>
      {to ? (
        <Link to={to}>
          <ArrowCircle />
        </Link>
      ) : null}
    </CoreValueItemContainer>
  )
}

export const CoreValueCollection = () => {
  return (
    <CoreValueCollectionContainer>
      {values.map(value => (
        <CoreValueItem value={value} key={value.header} />
      ))}
    </CoreValueCollectionContainer>
  )
}
