import React from 'react'
import styled, { withTheme } from 'styled-components'
import FloatingLabel, {
  floatingStyles,
  focusStyles,
  inputStyles,
  labelStyles,
} from 'floating-label-react'
import { ErrorMessage } from 'formik'

const ERROR_SIZE = '0.6rem'
export const InputContainer = styled.div`
  padding-bottom: ${ERROR_SIZE};
  margin-bottom: 1rem;
  position: relative;
`

export const Error = styled.div`
  font-size: ${ERROR_SIZE};
  position: absolute;
  bottom: 0;
  left: 0;
`

const InputComponent = ({ theme, name, ...rest }) => {
  const inputStyle = {
    floating: {
      ...floatingStyles,
      color: theme.secondaryColor,
      fontFamily: theme.defaultFont,
    },
    focus: {
      ...focusStyles,
      borderColor: theme.secondaryColor,
    },
    input: {
      ...inputStyles,
      borderBottomWidth: 2,
      borderBottomColor: theme.primaryColor,
      transition: theme.defaultTransition,
      fontFamily: theme.defaultFont,
      width: '100%',
      resize: 'none',
    },
    label: {
      ...labelStyles,
      paddingTop: '12px',
      marginBottom: '.25rem',
      fontFamily: theme.defaultFont,
      width: '100%',
    },
  }

  return (
    <InputContainer>
      <FloatingLabel styles={inputStyle} name={name} {...rest} />
      <ErrorMessage name={name}>{error => <Error>{error}</Error>}</ErrorMessage>
    </InputContainer>
  )
}

export const Input = withTheme(InputComponent)
